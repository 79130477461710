import React, { useMemo } from 'react';
import { LineChart as RechartLineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import CustomDot from './CustomDot';
import { observer } from 'mobx-react-lite';
import { DataPoint } from '../../../stores/graph-store';
import { Planet } from '../../../stores/planet-store';
import { useStores } from '../../../providers/store/use-stores';

interface LineChartProps {
  data: DataPoint[];
  planet: Planet;
}

interface ExtendedDataPoint {
  cx: number;
  cy: number;
  payload: DataPoint;
}

interface CursorPosition {
  x: number;
  y: number;
}

const LineChart = observer(({ planet, data }: LineChartProps) => {
  const {
    graphStore: { measurePeriodEnabled, graphCorrect },
  } = useStores();
  const [selectedPoints, setSelectedPoints] = React.useState<ExtendedDataPoint[]>([]);
  const [cursorPosition, setCursorPosition] = React.useState<CursorPosition>();
  const [lineAnchored, setLineAnchored] = React.useState<boolean>(false);
  const [tooltipMessage, setTooltipMessage] = React.useState<string | null>(null);

  const isCorrect = useMemo(() => {
    return graphCorrect.find((item) => item === planet.name);
  }, [graphCorrect, planet.name]);

  const isConsecutiveDips = (point1: ExtendedDataPoint, point2: ExtendedDataPoint) => {
    const dipPoints = data.filter((p) => p.value === 1);

    const index1 = dipPoints.findIndex((p) => p.name === point1.payload.name);
    const index2 = dipPoints.findIndex((p) => p.name === point2.payload.name);

    return Math.abs(index1 - index2) === 1;
  };

  const handlePointClick = (dataPoint: ExtendedDataPoint) => {
    if (!measurePeriodEnabled) return;
    const isDipPoint = dataPoint.payload.value === 1;

    if (!isDipPoint) {
      setTooltipMessage("Select 'dip' point");
      return;
    }

    if (selectedPoints.length === 0) {
      setSelectedPoints([dataPoint]);
      setTooltipMessage(null);
    } else if (selectedPoints.length === 1) {
      if (isConsecutiveDips(selectedPoints[0], dataPoint)) {
        setSelectedPoints((prev) => [...prev, dataPoint]);
        setLineAnchored(true);
        setTooltipMessage(null);
      } else {
        setTooltipMessage("Select consecutive 'dips'");
      }
    } else {
      setSelectedPoints([dataPoint]);
      setLineAnchored(false);
      setTooltipMessage(null);
    }
  };

  const handleMouseMove = (event: any) => {
    const { activeCoordinate } = event;
    if (activeCoordinate) {
      setCursorPosition(activeCoordinate);
    }
  };

  const calculateDifference = useMemo(() => {
    return planet.period;

    // sim based calculation (not to scale):
    // if (selectedPoints.length === 2) {
    //   const x1 = parseFloat(selectedPoints[0].payload.name);
    //   const x2 = parseFloat(selectedPoints[1].payload.name);
    //   const radius = calculateKeplerRadius(Number((10 / Math.abs(x2 - x1)).toFixed(1))).toFixed(1);
    //   return radius;
    // }
    // return '';
  }, [planet.period]);

  return (
    <div className="w-full relative">
      <ResponsiveContainer width="100%" height={100}>
        <RechartLineChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: -40,
            bottom: -10,
          }}
          onMouseMove={handleMouseMove}
        >
          <XAxis
            dataKey="name"
            interval={0}
            tick={false}
            axisLine={true}
            tickLine={true}
            stroke="white"
            label={{
              value: 'Time',
              angle: 0,
              position: 'insideBottom',
              offset: 15,
              fontSize: 12,
              fill: 'white',
            }}
          />
          <YAxis
            tick={false}
            axisLine={true}
            tickLine={true}
            stroke="white"
            label={{
              value: 'Intensity',
              angle: -90,
              position: 'insideTopLeft',
              offset: 43,
              fontSize: 12,
              fill: 'white',
            }}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="value"
            stroke={isCorrect ? planet.color : '#ccc'}
            dot={(dotProps) => (
              <CustomDot
                {...dotProps}
                isAnchored={lineAnchored && selectedPoints[1]?.payload === dotProps.payload}
                isSelected={selectedPoints[0]?.payload === dotProps.payload}
                isCorrect={isCorrect && planet.color}
                handleKeyboardSelect={() => handlePointClick(dotProps as any)}
              />
            )}
            strokeWidth={3}
            activeDot={{
              r: 6,
              onClick: (_, dataPoint) => handlePointClick(dataPoint as any),
            }}
          />
          {selectedPoints.length > 0 && (
            <>
              <line
                x1={selectedPoints[0].cx || 0}
                y1={selectedPoints[0].cy || 0}
                x2={lineAnchored ? selectedPoints[1].cx || 0 : cursorPosition ? cursorPosition.x - 10 : 0}
                y2={lineAnchored ? selectedPoints[1].cy || 0 : cursorPosition?.y || 0}
                stroke="#FFD585"
                strokeWidth={2}
              />
              {lineAnchored && (
                <>
                  <rect
                    x={(selectedPoints[0].cx + selectedPoints[1].cx) / 2 - 55}
                    y={(selectedPoints[0].cy + selectedPoints[1].cy) / 2 - 35}
                    width={110}
                    height={30}
                    rx={8}
                    ry={8}
                    fill="white"
                  />
                  <text
                    x={(selectedPoints[0].cx + selectedPoints[1].cx) / 2}
                    y={(selectedPoints[0].cy + selectedPoints[1].cy) / 2 - 14}
                    textAnchor="middle"
                    fill="black"
                    fontSize={16}
                  >
                    {`${calculateDifference} years`}
                  </text>
                </>
              )}
            </>
          )}
          <Tooltip
            content={<div style={tooltipMessage ? tooltipStyles : {}}>{tooltipMessage}</div>}
            position={cursorPosition}
          />
        </RechartLineChart>
      </ResponsiveContainer>
    </div>
  );
});

const tooltipStyles = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '4px',
  padding: '10px 15px',
  fontSize: '12px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  border: '1px solid #444',
  maxWidth: '200px',
  position: 'relative' as const,
  whiteSpace: 'pre-wrap' as const,
  zIndex: 100,
};

export default LineChart;
